<style>
.theme--light.newsTable > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #def3ff !important;
}
.theme--dark.newsTable > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #000000 !important;
}
.newsBtn{padding: 8px;border: 1px solid #ededed;border-radius: 6px;display: inline-block;}
.newsBtn:hover{background-color:#ffffff}
.theme--dark .newsBtn:hover{background-color:#0e0e0e}
</style>
<template>
<div>
  <!-- <v-row class="ma-0" v-for="item in news" :key="item">
    <span>{{item.sentiment}}</span><span>{{item.source}}</span><span>{{item.title}}</span>
  </v-row> -->

  <v-simple-table class="ma-2 newsTable">
      <tbody>
          <tr class="tableRow" v-for="(item,index) in news" :key="index">
              <td class="secondaryColor">{{item.date}}</td>
              <td class="secondaryColor">{{item.source}}</td>
              <td class="secondaryColor">{{item.time}}</td>
              <td class="primaryColor py-3"> {{item.Desc}}
                  <div class="pt-2">
                      <span class="newsBtn">Vedanta <span class="ml-3" :class="item.perc > 0 ? 'negativevalue' : 'positivevalue'">{{item.perc}} <span class="caption">(0.65%)</span></span></span>
                  </div> 
                  <!-- <img class="ml-4" height="50%" :src="item.imageUrl" alt="image"> -->
                  </td>
              <td class="secondaryColor">{{item.instrument}} <label class="ml-2 btnGreyClr fsize12 px-2 py-1 rounded-sm font-weight-bold primaryColor">{{item.exch}}</label></td>
          </tr>
      </tbody>
  </v-simple-table>
  </div>
</template>

<script>
export default {
  data:()=> ({
    news:[
    {time:'20 minutes ago',date:'20/08/2021',source:'Business Today',Desc:'Adani Group releases statement on seizure of hazardous cargo containers at Mundra Port',instrument:'ADANIPORTS',exch:'NSE',perc:-2.67,imageUrl:'https://assets-netstorage.groww.in/stock-assets/logos/INE160A01022.png'},
    {time:'20 minutes ago',date:'20/08/2021',source:'Business Today',Desc:'Adani Group releases statement on seizure of hazardous cargo containers at Mundra Port',instrument:'ADANIPORTS',exch:'NSE',perc:30.88,imageUrl:'https://assets-netstorage.groww.in/stock-assets/logos/INE160A01022.png'},
    {time:'20 minutes ago',date:'20/08/2021',source:'Business Today',Desc:'Adani Group releases statement on seizure of hazardous cargo containers at Mundra Port',instrument:'ADANIPORTS',exch:'NSE',perc:-2.67,imageUrl:'https://assets-netstorage.groww.in/stock-assets/logos/INE160A01022.png'},
    {time:'20 minutes ago',date:'20/08/2021',source:'Business Today',Desc:'Adani Group releases statement on seizure of hazardous cargo containers at Mundra Port',instrument:'ADANIPORTS',exch:'NSE',perc:-2.67,imageUrl:'https://assets-netstorage.groww.in/stock-assets/logos/INE160A01022.png'},
    {time:'20 minutes ago',date:'20/08/2021',source:'Business Today',Desc:'Adani Group releases statement on seizure of hazardous cargo containers at Mundra Port',instrument:'ADANIPORTS',exch:'NSE',perc:-2.67,imageUrl:'https://assets-netstorage.groww.in/stock-assets/logos/INE160A01022.png'},
    {time:'20 minutes ago',date:'20/08/2021',source:'Business Today',Desc:'Adani Group releases statement on seizure of hazardous cargo containers at Mundra Port',instrument:'ADANIPORTS',exch:'NSE',perc:32.43,imageUrl:'https://assets-netstorage.groww.in/stock-assets/logos/INE160A01022.png'},
    {time:'20 minutes ago',date:'20/08/2021',source:'Business Today',Desc:'Adani Group releases statement on seizure of hazardous cargo containers at Mundra Port',instrument:'ADANIPORTS',exch:'NSE',perc:-2.67,imageUrl:'https://assets-netstorage.groww.in/stock-assets/logos/INE160A01022.png'},
    {time:'20 minutes ago',date:'20/08/2021',source:'Business Today',Desc:'Adani Group releases statement on seizure of hazardous cargo containers at Mundra Port',instrument:'ADANIPORTS',exch:'NSE',perc:-2.67,imageUrl:'https://assets-netstorage.groww.in/stock-assets/logos/INE160A01022.png'},
    {time:'20 minutes ago',date:'20/08/2021',source:'Business Today',Desc:'Adani Group releases statement on seizure of hazardous cargo containers at Mundra Port',instrument:'ADANIPORTS',exch:'NSE',perc:-2.67,imageUrl:'https://assets-netstorage.groww.in/stock-assets/logos/INE160A01022.png'},
    ]
  })
}
</script>
<style>
</style>